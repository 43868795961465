import React from 'react';
import classNames from 'classnames';
import './work-card-inner.scss';
import { Heading } from '../Heading/Heading';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Tag } from '../Tag/Tag';
import { Link } from 'gatsby'; 

export const WorkCardInner = ({ title, category, description, image, tags, id, href }) => {
    const parsedImage = getImage(image);

    // Check if the href is an internal link (i.e., it starts with '/')
    const isInternalLink = href && href.startsWith('/');

    const cardClassName = classNames('work-card-inner', {
        'work-card-inner--link': href, 
    });

    const CardContent = (
        <div className={cardClassName} id={id}>
            <div className="work-card-inner__holder">
                <div className="work-card-inner__details">
                    <Heading level="h2">{title}</Heading>
                    <p className="category">{category}</p>
                    <div className="tags">
                        {tags.map((tag, index) => (
                            <Tag key={tag}>{tag}</Tag>
                        ))}
                    </div>
                    <p className="description">{description}</p>
                </div>
                <div className="work-card-inner__image">
                    <GatsbyImage
                        image={parsedImage}
                        className="rspimg project-img"
                        alt={title}
                    />
                </div>
            </div>
        </div>
    );

    if (href) {
        return isInternalLink ? (
            <Link to={href} className="work-card-link">
                {CardContent}
            </Link>
        ) : (
            <a href={href} className="work-card-link" target="_blank" rel="noopener noreferrer">
                {CardContent}
            </a>
        );
    }

    // Return only the card content without a link if href is not provided
    return CardContent;
};
