import React, { Fragment, useEffect } from "react";
import Layout from "../components/layout/layout";
import Fold from "../components/Fold/Fold";
import { TextPhotoSection } from "../components/TextPhotoSection/TextPhotoSection";
import { graphql, useStaticQuery } from "gatsby";
import FadeDown from "../components/FadeDown/FadeDown";
import CtaSection from "../components/CtaSection/CtaSection";
import Services from "../components/Services/Services";
import { CardService } from "../components/CardService/CardService";
import { CardWithImage } from "../components/CardWithImage/CardWithImage";
import { WorkSection } from "../components/WorkSection/WorkSection";
import { WorkCardInner } from "../components/WorkCardInner/WorkCardInner";

const IMAGE_QUERY = graphql`
  {
    AboutUsImg: file(relativePath: { eq: "about-us.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 500
          placeholder: BLURRED
          formats: [AUTO, JPG, WEBP, AVIF]
        )
      }
    }
    workImage1: file(relativePath: { eq: "our-work/bahler-project.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1000
          placeholder: BLURRED
          formats: [AUTO, JPG, WEBP, AVIF]
        )
      }
    }
    workImage2: file(relativePath: { eq: "our-work/savemave-project.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1000
          placeholder: BLURRED
          formats: [AUTO, JPG, WEBP, AVIF]
        )
      }
    }
    workImage3: file(
      relativePath: { eq: "our-work/jakob-muller-project.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1000
          placeholder: BLURRED
          formats: [AUTO, JPG, WEBP, AVIF]
        )
      }
    }
    workImage4: file(relativePath: { eq: "our-work/wintrado-project.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1000
          placeholder: BLURRED
          formats: [AUTO, JPG, WEBP, AVIF]
        )
      }
    }
    workImage5: file(relativePath: { eq: "our-work/caritas-project.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1000
          placeholder: BLURRED
          formats: [AUTO, JPG, WEBP, AVIF]
        )
      }
    }
    workImage6: file(relativePath: { eq: "our-work/ada-project.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1000
          placeholder: BLURRED
          formats: [AUTO, JPG, WEBP, AVIF]
        )
      }
    }
    dutyDingoImage: file(relativePath: { eq: "our-work/duty-dingo-project.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 952
          placeholder: BLURRED
          formats: [AUTO, JPG, WEBP, AVIF]
        )
      }
    }
    serviceImage1: file(
      relativePath: { eq: "our-work/services/consulting-and-strategy.jpeg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1000
          placeholder: BLURRED
          formats: [AUTO, JPG, WEBP, AVIF]
        )
      }
    }
    serviceImage2: file(
      relativePath: { eq: "our-work/services/ux-ui-design.jpeg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1000
          placeholder: BLURRED
          formats: [AUTO, JPG, WEBP, AVIF]
        )
      }
    }
    serviceImage3: file(
      relativePath: { eq: "our-work/services/software-development.jpeg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1000
          placeholder: BLURRED
          formats: [AUTO, JPG, WEBP, AVIF]
        )
      }
    }
    industryImage1: file(
      relativePath: { eq: "our-work/industries/finance.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 708
          placeholder: BLURRED
          formats: [AUTO, JPG, WEBP, AVIF]
        )
      }
    }
    industryImage2: file(
      relativePath: { eq: "our-work/industries/manufacturing.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 708
          placeholder: BLURRED
          formats: [AUTO, JPG, WEBP, AVIF]
        )
      }
    }
  }
`;

const OurWork = () => {
  const tempImage = useStaticQuery(IMAGE_QUERY);

  useEffect(() => {
    // Check if there is a anchor in the URL
    const hash = window.location.hash.substring(1);
    if (hash) {
      // Find the project card with the corresponding ID
      const element = document.getElementById(hash);
      if (element) {
        // Scroll to the card smoothly
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []); // run only once on mount

  return (
    <Layout
      pageTitle="Our Work - Solving Problems, One Project at A Time"
      description="Experience the remarkable missions we embark on. Missions where we team up with clients to build innovative digital products that take their business to the next level. Whatever your mission might be, we are your partners taking you through all the stages."
    >
      <FadeDown fold>
        <Fold
          title={
            <Fragment>
              <span>Solving Problems,</span> <br /> One Project at A Time
            </Fragment>
          }
          description="At the heart of everything we do is a passion for delivering performant and scalable solutions success for our clients. But don't just take our word for it – check out some of our work to see why our clients love us – and why you will too."
        />
      </FadeDown>

      <WorkSection>
        <FadeDown>
          <WorkCardInner
            title="Jakob Müller"
            href="/our-work/kickstage-develops-mymunet-an-advanced-iot-platform-for-jakob-muller-group"
            category="Web application"
            description="There are many terms that describe this exciting project we are doing with Jakob Müller, an industry leader in weaving from Frick, Switzerland. All the buzz aside, we are proudly collaborating to build a modular platform that is capable of storing and providing real-time analytics for thousands of weaving machines worldwide, as well as the ability to create & manage orders, detect potential production issues and help streamline the production process."
            image={tempImage.workImage3}
            tags={[
              "NodeJS",
              "Angular",
              "PostgreSQL",
              "GraphQL",
              "gRPC",
              "TypeScript",
              "UX/UI",
            ]}
            id="jakob-muller"
          />
        </FadeDown>
        <FadeDown>
          <WorkCardInner
            title="DutyDingo"
            href="/our-work/kickstage-develops-ai-powered-chatbot-for-dutydingo/"
            category="Chatbot"
            description="Our cutting-edge tool transforms Slack conversations into actionable tasks with just a few clicks. Seamlessly integrating with popular project management platforms like Trello, Jira, Asana, and ClickUp, it streamlines your workflow, ensuring that no important task is missed. Boost your team's productivity by automating task creation directly from your team's communication hub, saving time and reducing manual effort."
            image={tempImage.dutyDingoImage}
            tags={[
              "Open AI",
              "DSPy",
              "Llama",
              "Pstgres",
              "Qdrant",
              "Supabase",
              "Node.JS",
              "TypeScript",
            ]}
            id="duty-dingo"
          />
        </FadeDown>
        <FadeDown>
          <WorkCardInner
            title="Wintrado"
            category="Web application / Trading Platform"
            description="Trading platform which features real-time trade processing with the lowest possible latencies, accurate handling of payments and financial transactions, both with fiat and cryptocurrencies, a multitude of features in the frontend and backend, hundreds of configuration options, and all of that hidden behind a super simple, intuitive and gamified user interface."
            image={tempImage.workImage4}
            tags={[
              "React",
              "TypeScript",
              "UX/UI",
              "PWA",
              "NodeJS",
              "PostgreSQL",
              "gRPC",
              "GoLang",
            ]}
            id="wintrado"
          />
        </FadeDown>
        <FadeDown>
          <WorkCardInner
            title="Bähler"
            category="Mobile app, CMS"
            description="Here is what happens when a customer insight meets a group of developers - you get an awesome product!
                  Our team integrated some cutting edge technologies to create the Bähler Swiss mobile app!"
            image={tempImage.workImage1}
            tags={[
              "Flutter",
              "Ruby on Rails",
              "PostgreSQL",
              "Redis",
              "Sidekiq",
              "UX/UI",
            ]}
            id="bahler"
          />
        </FadeDown>
        <FadeDown>
          <WorkCardInner
            title="Caritas"
            category="Mobile app, CMS"
            description="In collaboration with Caritas Arnsberg, we've built a mobile app that allows the users to browse the latest Caritas-related news as well as be able to see who in their community needs volunteer help with detailed information of what, where and for how long."
            image={tempImage.workImage5}
            tags={["Flutter", "Ruby on Rails", "PostgreSQL", "UX/UI"]}
          />
        </FadeDown>
        <FadeDown>
          <WorkCardInner
            title="Ada"
            category="Mobile app"
            description="Inspired by Ada Lovelace, a poet and the world’s first computer programmer, the Ada Fellowship Program brings people from all industries together into a fully integrated learning journey on digitalisation and social change.
            The Ada Fellowship mobile application gives access to learning content and allows participants to connect. We have collaborated on the app since the very beginning of the program and are proudly maintaining it to this date."
            image={tempImage.workImage6}
            tags={["Flutter", "REST API", "UX/UI"]}
          />
        </FadeDown>
      </WorkSection>

      <Services
        title="Services"
        description="From planning to delivery and beyond, Kickstage delivers custom end-to-end software development services to help clients innovate at scale and unlock business value."
      >
        <CardService
          title="Consulting & Strategy"
          description={
            <Fragment>
              <strong>
                Got a fantastic product idea but don't have a blueprint to make
                it happen?
              </strong>
              <p>
                Kickstage is here to help. We'll dive deep into your industry to
                validate your objectives and build a strategic roadmap that lays
                out the technologies to get there. With Kickstage, it's never
                just about the project at hand. It's about building trust and
                ensuring your long-term success. We support you with accurate
                technical documentation to guide future software maintenance and
                development.
              </p>
            </Fragment>
          }
          image={tempImage.serviceImage1}
        />
        <CardService
          title="UX/UI Design"
          description={
            <Fragment>
              <strong>
                Design an engaging product that's easy to use, intuitive and
                functional.
              </strong>
              <p>
                Your web or mobile app is only as good as the experience it
                offers its users. That's why Kickstage takes a human-centred
                approach to product design.
              </p>
              <p>
                We craft clean, interactive interfaces with model design tools
                that enrich and enliven the journeys of users on your websites
                and apps. Whether you need to design an app from scratch or
                upgrade an existing interface, we'll be with you every step of
                the way.
              </p>
            </Fragment>
          }
          image={tempImage.serviceImage2}
        />
        <CardService
          title="Software Development"
          description={
            <Fragment>
              <strong>
                Bring your ideas to life with our full-cycle software
                development services.
              </strong>
              <p>
                Building a digital product, web or mobile app can seem
                overwhelming, especially when starting from scratch. With
                Kickstage, it doesn't have to be. From market research to
                product development and launch, our team of experts will work
                with you to build a solutionmobile and website apps that
                achieves your business goals.
              </p>
            </Fragment>
          }
          image={tempImage.serviceImage3}
        />
      </Services>

      <Services
        twoCards
        title={
          <Fragment>
            Industries <span>we serve</span>
          </Fragment>
        }
        description="Kickstage empowers clients with world-class, custom software solutions that drive maximum value across the manufacturing and finance sectors."
      >
        <CardWithImage
          title="Manufacturing"
          description={
            <Fragment>
              <p>
                Find out how Kickstage streamlines manufacturing processes with
                smart, connected manufacturing solutions.
              </p>
            </Fragment>
          }
          ctaText="Learn more"
          ctaLink="/our-work/manufacturing-industry"
          image={tempImage.industryImage2}
        />
        <CardWithImage
          title="Finance"
          description={
            <Fragment>
              <p>
                Discover how our scalable low-latency solutions enable
                high-speed transactions for firms across the finance sector.
              </p>
            </Fragment>
          }
          ctaText="Learn more"
          ctaLink="/our-work/finance-industry"
          image={tempImage.industryImage1}
        />
      </Services>
      <TextPhotoSection
        title={
          <Fragment>
            Kickstage<span>, An Intro</span>
          </Fragment>
        }
        description="With some of the brightest minds in the digital tech industry, Kickstage is a software development consultancy driven by excellence.
        We live for unlocking business value through technology and enjoy the thrill of solving client problems with precision and passion. Wherever you are on your digital journey, our brilliant team will work to make your vision a reality."
        image={tempImage.AboutUsImg}
        btnLink="/about/"
        btnText="Get to know us"
        radialTextDecoration
        radialTextDecorationColor="yellow"
        radialTextDecorationPosition="top-right"
        extraSmallPaddingBottom
      />
      <CtaSection
        title="Let's Chat"
        label="What Can We Do For You?"
        link="/contact/"
      />
    </Layout>
  );
};

export default OurWork;
